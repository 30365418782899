import "styles/pages/order-info.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const OrderInfo = () => {
  const title = "Czas realizacji zamówienia"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="order-info">
        <div className="container-fluid">
          <PageHeader title={title} />
          <PageSubHeader title="Informacje ogólne" />

          <p>
            Każdy towar w przypadku <strong>płatności przelewem</strong> lub{" "}
            <strong>płatności online</strong> wysyłamy zaraz po zaksięgowaniu
            pieniędzy na naszym koncie. Przesyłki dostarczają firmy kurierskie -
            czas dostawy zależy od przewoźnika, rodzaju przesyłki oraz adresu
            odbiorcy. Zazwyczaj zajmuje to do 2 dni roboczych.{" "}
            <strong>Uwaga!</strong> Przy płatności przelewem internetowym, czas
            otrzymania paczki wydłuża się o czas realizacji przelewu.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default OrderInfo
